import { Project } from "../Components/Project";
import { IntroductionLocal } from "../Components/IntroductionLocal";

export const StartPageLocal = () => {
  return (
    <div className="App">
      <IntroductionLocal />
      <div className="projects">
        <Project
          title={"Get Healthy"}
          link={"https://gethealthy.pasha.software"}
          description={`Get healthy represents an online training platform where trainees subscribe to various training programs from certified trainers. It was done as a project by a team of 5.`}
        ></Project>
      </div>
    </div>
  );
};
