import React from "react";
import "../App.css";
import { NavLink } from "react-router-dom";

export const IntroductionInternational = () => (
  <div className="introduction">
    <h2>About Me</h2>
    <p>Hi there! My name is Pasha and welcome to my website.</p>
    <p>
      I am a software engineering student passionate about creating software
      solutions.
    </p>
    <p>
      You can view my github profile on the following link:{" "}
      <a
        href="https://github.com/PashaSpammer"
        target="_blank"
        rel="noopener noreferrer"
      >
        Github
      </a>
    </p>
    <p>
      Proud owner of the Community SUS Discord server. <i>Invite only.</i> More
      info on: <NavLink to={"/sus"}>link</NavLink>
    </p>
  </div>
);
