import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { StartPageLocal } from "../Pages/StartPageLocal";
import { StartPageInternational } from "../Pages/StartPageInternational";
import { SUSServerPage } from "../Pages/SUSServerPage";

export const router = createBrowserRouter([
  {
    path: "",
    children: [
      {
        index: true,
        element: <StartPageInternational />,
      },
      {
        path: "ba",
        element: <StartPageLocal />,
      },
      {
        path: "sr",
        element: <StartPageLocal />,
      },
      {
        path: "sus",
        element: <SUSServerPage />,
      },
    ],
  },
]);
