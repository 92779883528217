import React from "react";
import "../App.css";

export const IntroductionLocal = () => {
  return (
    <div className="introduction">
      <h2>About Me</h2>
      <p>Hi there! My name is Vladan Pasagic and welcome to my website.</p>
      <p>
        I am a software engineering student passionate about creating software
        solutions.
      </p>
      <p>
        You can view my github profile on the following link:{" "}
        <a
          href="https://github.com/VladanPasagic"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
      </p>
      <p>Bellow you can see web projects I participated in while in school.</p>
    </div>
  );
};
